import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import {
  EOrgType,
  EParamChannelType,
  ERetailerReportPageType,
  EUserRole,
} from '@unfoldrtech/portal-mic'
import { PortalRoutes } from './PortalRoutes'
import AuthenticatedRoute from './AuthenticatedRoute'
import ReportPageWrapper from '../ReportPageWrapper'
import { lazyRetry } from '../../utils/lazyRetry'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import {
  PORTAL_FF_DA,
  PORTAL_FF_DOOH_REPORTING_VIEW,
  PORTAL_FF_REPORTING_SEARCH_TERMS,
} from '../../utils/constants'
import { ReportingDOOHLocationTemplate } from '../ReportPageWrapperV3/dooh-location/components/ReportingDOOHLocationTemplate'
import { ReportingDAAudienceTemplate } from '../ReportPageWrapperV3/da-audience/components/ReportingDAAudienceTemplate'
import { ReportingSPProductsTemplate } from '../ReportPageWrapperV3/sp-products/components/ReportingSPProductsTemplate'
import NotFound from '../../pages/NotFound'

const Overview = lazy(() =>
  lazyRetry(() => import('../../pages/Retailer/Routes/Analytics/Overview'))
)
const Campaigns = lazy(() =>
  lazyRetry(() => import('../../pages/Retailer/Routes/Analytics/Campaigns'))
)
const AdGroups = lazy(() =>
  lazyRetry(() => import('../../pages/Retailer/Routes/Analytics/AdGroups'))
)
const AdvertisersReport = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/Analytics/AdvertisersReport')
  )
)

const RetailerAnalyticsRoutes = () => {
  const featureFlags = useFeatureFlags()

  const isPortalDAFlagEnabled = featureFlags[PORTAL_FF_DA]
  const isPortalDOOHReportingViewFlagEnabled =
    featureFlags[PORTAL_FF_DOOH_REPORTING_VIEW]

  return (
    <PortalRoutes>
      <Route
        path=""
        element={
          <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
            <Overview />
          </AuthenticatedRoute>
        }
      >
        <Route
          path="advertisers-all"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportPageWrapper
                title="retailer.nav.advertisers"
                orgType={EOrgType.Retailer}
                showSwitch={false}
              />
            </AuthenticatedRoute>
          }
          /* exact */
        />

        <Route
          path="campaigns-all"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportPageWrapper
                title="advertiser.nav.campaigns"
                orgType={EOrgType.Retailer}
                retailerReportPageType={
                  ERetailerReportPageType.CampaignsAdvertiser
                }
                showSwitch={false}
              />
            </AuthenticatedRoute>
          }
          /* exact */
        />
        <Route
          path="campaigns-sponsoredProducts"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportPageWrapper
                title="reporting.header.campaigns.sponsoredProducts"
                orgType={EOrgType.Retailer}
                retailerReportPageType={
                  ERetailerReportPageType.CampaignsAdvertiser
                }
                channelType={EParamChannelType.SponsoredProduct}
                showSwitch={false}
              />
            </AuthenticatedRoute>
          }
          /* exact */
        />
        {isPortalDAFlagEnabled && (
          <Route
            path="campaigns-da"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="reporting.header.campaigns.displayAds"
                  orgType={EOrgType.Retailer}
                  retailerReportPageType={
                    ERetailerReportPageType.CampaignsAdvertiser
                  }
                  channelType={EParamChannelType.Da}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
            /* exact */
          />
        )}
        {isPortalDOOHReportingViewFlagEnabled && (
          <Route
            path="campaigns-dooh"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="reporting.header.campaigns.dooh"
                  orgType={EOrgType.Retailer}
                  retailerReportPageType={
                    ERetailerReportPageType.CampaignsAdvertiser // EEntityType.Campaign
                  }
                  channelType={EParamChannelType.Dooh}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
            /* exact */
          />
        )}
        <Route
          path="products"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportingSPProductsTemplate />
            </AuthenticatedRoute>
          }
        />
      </Route>

      {/* Analytics pages for an advertiser showing campaigns */}
      <Route
        path="brands/:advertiserId"
        element={
          <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
            <AdvertisersReport />
          </AuthenticatedRoute>
        }
      >
        <Route
          path="campaigns-all"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportPageWrapper
                title="advertiser.nav.campaigns"
                orgType={EOrgType.Retailer}
                retailerReportPageType={ERetailerReportPageType.Campaigns}
                showSwitch={false}
              />
            </AuthenticatedRoute>
          }
          /* exact */
        />
        <Route
          path="campaigns-sponsoredProducts"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportPageWrapper
                title="reporting.header.campaigns.sponsoredProducts"
                orgType={EOrgType.Retailer}
                retailerReportPageType={ERetailerReportPageType.Campaigns}
                channelType={EParamChannelType.SponsoredProduct}
                showSwitch={false}
              />
            </AuthenticatedRoute>
          }
          /* exact */
        />

        {isPortalDAFlagEnabled && (
          <Route
            path="campaigns-da"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="reporting.header.campaigns.displayAds"
                  orgType={EOrgType.Retailer}
                  retailerReportPageType={ERetailerReportPageType.Campaigns}
                  channelType={EParamChannelType.Da}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
            /* exact */
          />
        )}
        {isPortalDOOHReportingViewFlagEnabled && (
          <Route
            path="campaigns-dooh"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="reporting.header.campaigns.dooh"
                  orgType={EOrgType.Retailer}
                  retailerReportPageType={ERetailerReportPageType.Campaigns}
                  channelType={EParamChannelType.Dooh}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
            /* exact */
          />
        )}
      </Route>

      {/* Analytics pages for an advertiser showing adgroups for a campaign */}
      <Route
        path="brands/:advertiserId/campaigns/:campaignId"
        element={
          <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
            <Campaigns />
          </AuthenticatedRoute>
        }
      >
        <Route
          path="adgroups"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportPageWrapper
                title="reporting.header.adGroups"
                orgType={EOrgType.Retailer}
                retailerReportPageType={ERetailerReportPageType.Adgroups}
                showSwitch={false}
              />
            </AuthenticatedRoute>
          }
          /* exact */
        />
        {(isPortalDAFlagEnabled || isPortalDOOHReportingViewFlagEnabled) && (
          <Route
            path="creatives"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="reporting.header.creatives"
                  orgType={EOrgType.Retailer}
                  retailerReportPageType={ERetailerReportPageType.Creatives}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
            /* exact */
          />
        )}
        {isPortalDAFlagEnabled && (
          <Route
            path="positions"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="reporting.header.positions"
                  orgType={EOrgType.Retailer}
                  retailerReportPageType={ERetailerReportPageType.Positions}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
            /* exact */
          />
        )}
        {isPortalDOOHReportingViewFlagEnabled && (
          <Route
            path="screen"
            element={
              <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                <ReportPageWrapper
                  title="reporting.header.screens"
                  orgType={EOrgType.Retailer}
                  retailerReportPageType={'screens' as ERetailerReportPageType}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
            /* exact */
          />
        )}
        {isPortalDAFlagEnabled && (
          <Route
            path="audiences"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingDAAudienceTemplate />
              </AuthenticatedRoute>
            }
          />
        )}
      </Route>

      {/* Analytics pages  for an advertiser showing products/keywords/categories for a campaign adgroup */}
      <Route
        path="brands/:advertiserId/campaigns/:campaignId/adgroups/:adgroupId"
        element={
          <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
            <AdGroups />
          </AuthenticatedRoute>
        }
      >
        <Route
          path="products"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportPageWrapper
                title="products"
                retailerReportPageType={ERetailerReportPageType.Products}
                orgType={EOrgType.Retailer}
                navigateDisabled={true}
                showSwitch={false}
              />
            </AuthenticatedRoute>
          }
          /* exact */
        />

        <Route
          path="categories"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportPageWrapper
                title="categories"
                retailerReportPageType={ERetailerReportPageType.Categories}
                orgType={EOrgType.Retailer}
                navigateDisabled={true}
                showSwitch={false}
              />
            </AuthenticatedRoute>
          }
          /* exact */
        />

        <Route
          path="keywords"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportPageWrapper
                title="keywords"
                retailerReportPageType={ERetailerReportPageType.Keywords}
                orgType={EOrgType.Retailer}
                navigateDisabled={true}
                showSwitch={false}
              />
            </AuthenticatedRoute>
          }
        />
        {featureFlags[PORTAL_FF_REPORTING_SEARCH_TERMS] && (
          <Route
            path="search-terms"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="search.terms"
                  subTitle="search.terms.description"
                  retailerReportPageType={
                    ERetailerReportPageType.KeywordsInsights
                  }
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  virtual={true}
                />
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalDAFlagEnabled && (
          <Route
            path="pagePosition"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="pagePosition"
                  retailerReportPageType={ERetailerReportPageType.Pages}
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  showSwitch={false}
                  channelType={EParamChannelType.Da}
                />
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalDAFlagEnabled && (
          <Route
            path="positions"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="positions"
                  retailerReportPageType={ERetailerReportPageType.Positions}
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
          />
        )}
        {(isPortalDAFlagEnabled || isPortalDOOHReportingViewFlagEnabled) && (
          <Route
            path="creatives"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="creatives"
                  retailerReportPageType={ERetailerReportPageType.Creatives}
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalDOOHReportingViewFlagEnabled && (
          <Route
            path="locations"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingDOOHLocationTemplate />
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalDAFlagEnabled && (
          <Route
            path="audiences"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingDAAudienceTemplate />
              </AuthenticatedRoute>
            }
          />
        )}
      </Route>

      {/* Analytics pages showing adgroups for a campaign */}
      <Route
        path="campaigns/:campaignId"
        element={
          <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
            <Campaigns />
          </AuthenticatedRoute>
        }
      >
        <Route
          path="adgroups"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportPageWrapper
                title="reporting.header.adGroups"
                orgType={EOrgType.Retailer}
                retailerReportPageType={ERetailerReportPageType.Adgroups}
                showSwitch={false}
              />
            </AuthenticatedRoute>
          }
          /* exact */
        />
        {isPortalDAFlagEnabled && (
          <Route
            path="positions"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="reporting.header.positions"
                  orgType={EOrgType.Retailer}
                  retailerReportPageType={ERetailerReportPageType.Positions}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
          />
        )}
        {(isPortalDAFlagEnabled || isPortalDOOHReportingViewFlagEnabled) && (
          <Route
            path="creatives"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="reporting.header.creatives"
                  orgType={EOrgType.Retailer}
                  retailerReportPageType={ERetailerReportPageType.Creatives}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalDAFlagEnabled && (
          <Route
            path="audiences"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingDAAudienceTemplate />
              </AuthenticatedRoute>
            }
          />
        )}
      </Route>

      {/* Analytics pages showing products/keywords/categories for a campaign adgroup */}
      <Route
        path="campaigns/:campaignId/adgroups/:adgroupId"
        element={
          <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
            <AdGroups />
          </AuthenticatedRoute>
        }
      >
        <Route
          path="products"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportPageWrapper
                title="products"
                retailerReportPageType={ERetailerReportPageType.Products}
                orgType={EOrgType.Retailer}
                navigateDisabled={true}
                showSwitch={false}
              />
            </AuthenticatedRoute>
          }
          /* exact */
        />

        <Route
          path="categories"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportPageWrapper
                title="categories"
                retailerReportPageType={ERetailerReportPageType.Categories}
                orgType={EOrgType.Retailer}
                navigateDisabled={true}
                showSwitch={false}
              />
            </AuthenticatedRoute>
          }
          /* exact */
        />

        <Route
          path="keywords"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportPageWrapper
                title="keywords"
                retailerReportPageType={ERetailerReportPageType.Keywords}
                orgType={EOrgType.Retailer}
                navigateDisabled={true}
                showSwitch={false}
              />
            </AuthenticatedRoute>
          }
        />
        {featureFlags[PORTAL_FF_REPORTING_SEARCH_TERMS] && (
          <Route
            path="search-terms"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="search.terms"
                  subTitle="search.terms.description"
                  retailerReportPageType={
                    ERetailerReportPageType.KeywordsInsights
                  }
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  virtual={true}
                />
              </AuthenticatedRoute>
            }
          />
        )}
        {(isPortalDAFlagEnabled || isPortalDOOHReportingViewFlagEnabled) && (
          <Route
            path="creatives"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="creatives"
                  retailerReportPageType={ERetailerReportPageType.Creatives}
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalDAFlagEnabled && (
          <Route
            path="positions"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="positions"
                  retailerReportPageType={ERetailerReportPageType.Positions}
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalDOOHReportingViewFlagEnabled && (
          <Route
            path="locations"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingDOOHLocationTemplate />
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalDAFlagEnabled && (
          <Route
            path="audiences"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingDAAudienceTemplate />
              </AuthenticatedRoute>
            }
          />
        )}
      </Route>
      <Route path="*" element={<NotFound />} />
    </PortalRoutes>
  )
}

export default RetailerAnalyticsRoutes
