import { createGlobalStyle } from 'styled-components'
import { ORG_DEFAULT_THEME, PORTAL_DEFAULT_THEME } from './constants'
import { IThemeProps } from '../../../models/interfaces'

export const AppTheme = createGlobalStyle<IThemeProps>`
  ${(props: IThemeProps) => {
    const fontColors = props.theme?.colors?.font || {}
    const backgroundColors = props.theme?.colors?.background || {}
    const borderColors = props.theme?.colors?.border || {}
    const buttonColors = props.theme.colors?.button || {}
    const badgeColors = props.theme?.colors?.badge || {}
    const chartColors = props.theme.colors?.chart || {}

    const FONT_COLORS = ORG_DEFAULT_THEME.colors?.font || {}
    const BACKGROUND_COLORS = ORG_DEFAULT_THEME.colors?.background || {}
    const BORDER_COLORS = ORG_DEFAULT_THEME.colors?.border || {}
    const BUTTON_COLORS = ORG_DEFAULT_THEME.colors?.button || {}
    const BADGE_COLORS = ORG_DEFAULT_THEME.colors?.badge || {}
    const CHART_COLORS = ORG_DEFAULT_THEME.colors?.chart || {}

    const FONT_SIZES = PORTAL_DEFAULT_THEME.sizes?.font || {}
    const ICON_SIZES = PORTAL_DEFAULT_THEME.sizes?.icon || {}
    const BUTTON_SIZES = PORTAL_DEFAULT_THEME.sizes?.button || {}
    const LOGO_SIZES = PORTAL_DEFAULT_THEME.sizes?.logo || {}

    const GUTTER_SPACING = PORTAL_DEFAULT_THEME.spacing?.gutter || 8
    const LETTER_SPACING = PORTAL_DEFAULT_THEME.spacing?.letter || 0.86
    const ROW_SPACING = PORTAL_DEFAULT_THEME.spacing?.row || {}

    return `
    :root {
      
      // Global Styles
      svg {
        transform: translate(0px, -1px);
      }
      
      p {
        margin-bottom: unset;
      }
  
      .form-control:focus {
        box-shadow: unset;
      }

      body.modal-open > :not(.modal, .tooltip, iframe, #filter-popover) {
        filter: blur(4px);
      }      

      // MISC
      --bs-breadcrumb-divider: "/";
      --row-height-default: ${ROW_SPACING.height}px;
      
      // SIZES
      --font-size-h1: ${FONT_SIZES.h1};
      --font-size-h2: ${FONT_SIZES.h2};
      --font-size-h3: ${FONT_SIZES.h3};
      --font-size-h5: ${FONT_SIZES.h5};
      --font-size-pa: ${FONT_SIZES.pa};
      --font-size-ps: ${FONT_SIZES.ps};
      --font-size-xs: ${FONT_SIZES.xs};
      --font-size-sm: ${FONT_SIZES.sm};
      --icon-size-xs: ${ICON_SIZES.xs};
      --icon-size-sm: ${ICON_SIZES.sm};
      --icon-size-no: ${ICON_SIZES.no};
      --icon-size-lg: ${ICON_SIZES.lg};
      --icon-size-xl: ${ICON_SIZES.xl};
      --button-size-xs: ${BUTTON_SIZES.xs};
      --button-size-sm: ${BUTTON_SIZES.sm};
      --button-size-no: ${BUTTON_SIZES.no};
      --button-size-lg: ${BUTTON_SIZES.no};
      --button-size-xl: ${BUTTON_SIZES.lg};
      --logo-size-no: ${LOGO_SIZES.no};

      // SPACING
      --gutter-mini: ${GUTTER_SPACING * 0.5}px;
      --gutter-1x: ${GUTTER_SPACING * 1}px;
      --gutter-2x: ${GUTTER_SPACING * 2}px;
      --gutter-3x: ${GUTTER_SPACING * 3}px;
      --gutter-4x: ${GUTTER_SPACING * 4}px;
      --gutter-5x: ${GUTTER_SPACING * 5}px;
      --gutter-6x: ${GUTTER_SPACING * 6}px;
      --gutter-7x: ${GUTTER_SPACING * 7}px;
      --gutter-8x: ${GUTTER_SPACING * 8}px;
      --gutter-9x: ${GUTTER_SPACING * 9}px;
      --gutter-10x: ${GUTTER_SPACING * 10}px;
      --gutter-20x: ${GUTTER_SPACING * 20}px;
      --gutter-default: ${GUTTER_SPACING}px;
      --margin-default: ${GUTTER_SPACING * 2}px;
      --padding-default: ${GUTTER_SPACING * 3}px;
      --line-height-default: ${GUTTER_SPACING * 6}px;
      --letter-spacing-default: ${LETTER_SPACING}px;

      // COLORS
      --border-color-default: ${borderColors.primary || BORDER_COLORS.primary};
      --border-color-secondary: ${
        borderColors.secondary || BORDER_COLORS.secondary
      };
      --border-color-dashed: ${borderColors.dashed || BORDER_COLORS.dashed}; 
      --border-color-active: ${borderColors.active || BORDER_COLORS.active};
      --border-color-light: ${borderColors.light || BORDER_COLORS.light};
      --border-color-dark: ${borderColors.dark || BORDER_COLORS.dark};
      --border-color-error: ${borderColors.error || BORDER_COLORS.error};
      --border-color-filter-bottom: ${
        borderColors.filterBottom || BORDER_COLORS.filterBottom
      };
      --border-color-filter-box-shadow: ${
        borderColors.filterBoxShadow || BORDER_COLORS.filterBoxShadow
      };

      --font-color-default: ${fontColors.primary || FONT_COLORS.primary};
      --font-color-secondary: ${fontColors.secondary || FONT_COLORS.secondary};
      --font-color-light: ${fontColors.light || FONT_COLORS.light};
      --font-color-dark: ${fontColors.dark || FONT_COLORS.dark};
      --font-color-action: ${fontColors.action || FONT_COLORS.action};
      --font-color-active: ${fontColors.active || FONT_COLORS.active};
      --font-color-url: ${fontColors.url || FONT_COLORS.url};
      --font-color-placeholder: ${
        fontColors.placeholder || FONT_COLORS.placeholder
      };
      --font-color-error: ${fontColors.error || FONT_COLORS.error};

      --background-color-default: ${
        backgroundColors.primary || BACKGROUND_COLORS.primary
      };
      --background-color-secondary: ${
        backgroundColors.secondary || BACKGROUND_COLORS.secondary
      };
      --background-color-light: ${
        backgroundColors.light || BACKGROUND_COLORS.light
      };
      --background-color-dark: ${
        backgroundColors.dark || BACKGROUND_COLORS.dark
      };
      --background-color-disabled: ${
        backgroundColors.disabled || BACKGROUND_COLORS.disabled
      };
      --background-color-active: ${
        backgroundColors.active || BACKGROUND_COLORS.active
      };
      --background-color-textarea: ${
        backgroundColors.textarea || BACKGROUND_COLORS.textarea
      };
      --background-color-confirm: ${
        backgroundColors.confirm || BACKGROUND_COLORS.confirm
      };
      --background-color-toggle: ${
        backgroundColors.toggle || BACKGROUND_COLORS.toggle
      };
      --background-color-table-header:${
        backgroundColors.tableHeader || BACKGROUND_COLORS.tableHeader
      };
      --background-color-approve-button: #3bc887;
      --background-color-reject-button: #e13c3c;

      --button-color-default: ${buttonColors.primary || BUTTON_COLORS.primary};
      --button-color-secondary: ${
        buttonColors.secondary || BUTTON_COLORS.secondary
      };
      --button-color-disabled: ${
        buttonColors.disabled || BUTTON_COLORS.disabled
      };

      --badge-color-active: ${badgeColors.active || BADGE_COLORS.active};
      --badge-color-inactive: ${badgeColors.inactive || BADGE_COLORS.inactive}; 
      --badge-color-disabled: ${badgeColors.disabled || BADGE_COLORS.disabled}; 
      --badge-color-pending: ${badgeColors.pending || BADGE_COLORS.pending};

      --toast-color-success: #3bc887;
      --toast-color-warning: #ff912d;
      --toast-color-error: #e13c3c;
      
      --chart-grid-line-color-default: ${
        chartColors.grid?.primary || CHART_COLORS.grid?.primary
      };
    }`
  }}
`
