/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import {
  Container,
  Label,
  StyledButton,
  StyledCheckbox,
  StyledCheckboxInput,
  StyledForm,
  StyledModal,
} from '../Global'
import I18NText from '../I18NText'
import { EUserEmailPreferencesType } from '../../models/core'
import { AppContext, TTranslationKey } from '../../models'
import { SuccessToast } from '../ToastCard/SuccessToast'
import { ErrorToast } from '../ToastCard/ErrorToast'
import useShowToast from '../../hooks/useShowToast'
import { useRegisterUserConsent } from '../../hooks/misc'
import firebaseAuth from '../../services/firebaseAuth'

export type TUserConsentModalForm = {
  marketingEmailsConsent: boolean
  termsAndConditionsConsent: boolean
}

interface IUserConsentModalProps {
  onConsent: () => void
}

export const UserConsentModal = ({ onConsent }: IUserConsentModalProps) => {
  const intl = useIntl()
  const [appContext] = useContext(AppContext)
  const [showModal] = useState(
    !appContext.userProfile?.hasConsentTermsAndConditions
  )

  const marketingEmailsConsent = useMemo(
    () =>
      !!appContext.userProfile?.emailPreferences?.find(
        (emailPreference) =>
          emailPreference.type === EUserEmailPreferencesType.Marketing
      )?.hasConsented,
    [appContext.userProfile?.emailPreferences]
  )

  const {
    handleSubmit,
    register,
    formState: { isValid },
    getValues,
  } = useForm<TUserConsentModalForm>({
    defaultValues: { marketingEmailsConsent, termsAndConditionsConsent: false },
    mode: 'onChange',
  })

  const {
    mutate: registerUserConsent,
    isSuccess: isSuccessRegisterUserConsent,
    isLoading: isLoadingRegisterUserConsent,
    isError: isErrorRegisterUserConsent,
    error: errorRegisterUserConsent,
  } = useRegisterUserConsent()

  const [showErrorToast, setShowErrorToast] = useShowToast(
    isErrorRegisterUserConsent
  )
  const [showSuccessToast, setShowSuccessToast] = useShowToast(false)

  const onSubmit = async (data: TUserConsentModalForm) => {
    const authenticationCode = await firebaseAuth.getIdToken()

    await registerUserConsent({
      payload: {
        authenticationCode,
        emailPreferences: [
          {
            type: EUserEmailPreferencesType.Marketing,
            hasConsented: !!data.marketingEmailsConsent,
          },
        ],
      },
    })
  }

  useEffect(() => {
    if (isErrorRegisterUserConsent) {
      setShowErrorToast(true)
    }
  }, [isErrorRegisterUserConsent])

  useEffect(() => {
    if (isSuccessRegisterUserConsent) {
      onConsent()
    }
  }, [isSuccessRegisterUserConsent])

  return (
    <>
      <StyledModal
        centered
        onHide={() => {}}
        show={showModal}
        animation={false}
        data-testid="user-consent-modal"
      >
        <StyledModal.Header>
          <StyledModal.Title>
            <Container
              display="flex"
              flexDirection="column"
              marginBottom="var(--gutter-3x)"
            >
              <I18NText
                id="user.consent.modal.title"
                preset="h2"
                fontFamily="Archivo-SemiBold"
                lh="1.25"
                data-testid="user-consent-modal-title"
              />
            </Container>
          </StyledModal.Title>
        </StyledModal.Header>
        <StyledModal.Body>
          <Container>
            <I18NText
              id="user.consent.modal.description"
              preset="text"
              lh="1.25"
              data-testid="user-consent-modal-title-description"
              values={{
                link: (
                  <a
                    target="_blank"
                    href="https://streaem.com/terms-conditions"
                    rel="noreferrer"
                  >
                    <I18NText id="t.and.c" />
                  </a>
                ),
              }}
            />
          </Container>
          <StyledForm
            id="user-consent-modal-form"
            data-testid="user-consent-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container
              width="400px"
              paddingBottom="var(--gutter-1x)"
              paddingTop="var(--gutter-4x)"
            >
              <Container>
                <StyledCheckbox>
                  <Container display="flex" margin="auto">
                    <Label
                      display="flex"
                      htmlFor="userConsentModalTermsAgreed"
                      alignItems="center"
                    >
                      <Container marginRight="var(--gutter-2x)">
                        <StyledCheckboxInput
                          defaultChecked={false}
                          id="userConsentModalTermsAgreed"
                          type="checkbox"
                          display="flex"
                          data-testid="user-consent-form-checkbox-terms"
                          {...register('termsAndConditionsConsent', {
                            required: true,
                          })}
                        />
                      </Container>
                      <I18NText
                        data-testid="user-consent-form-label-terms"
                        id="t.and.c"
                        preset="paragraph opensans bold"
                      />
                    </Label>
                  </Container>
                </StyledCheckbox>
                <Container marginLeft="var(--gutter-4x)">
                  <I18NText
                    id="user.consent.form.checkbox.terms.description"
                    preset="text"
                    lh="1.25"
                    data-testid="user-consent-form-checkbox-terms-description"
                    values={{
                      link: (
                        <a
                          target="_blank"
                          href="https://streaem.com/terms-conditions"
                          rel="noreferrer"
                        >
                          <I18NText id="t.and.c" />
                        </a>
                      ),
                    }}
                  />
                </Container>
              </Container>

              <Container paddingTop="var(--margin-default)">
                <Container id={EUserEmailPreferencesType.Marketing}>
                  <StyledCheckbox>
                    <Container display="flex" margin="auto">
                      <Label
                        display="flex"
                        htmlFor="userConsentModalMarketing"
                        alignItems="center"
                      >
                        <Container marginRight="var(--gutter-2x)">
                          <StyledCheckboxInput
                            defaultChecked={marketingEmailsConsent}
                            id="userConsentModalMarketing"
                            type="checkbox"
                            display="flex"
                            data-testid="user-consent-form-checkbox-marketing-emails"
                            {...register('marketingEmailsConsent')}
                          />
                        </Container>

                        <I18NText
                          data-testid="user-consent-form-label-marketing-emails"
                          id={`${EUserEmailPreferencesType.Marketing.toLowerCase()}.communication`}
                          preset="paragraph opensans bold"
                        />
                      </Label>
                    </Container>
                  </StyledCheckbox>
                  <Container marginLeft="var(--gutter-4x)">
                    <I18NText
                      id="user.consent.form.checkbox.marketing.emails.description"
                      preset="text"
                      data-testid="user-consent-form-checkbox-marketing-emails-description"
                    />
                  </Container>
                </Container>
              </Container>
            </Container>
          </StyledForm>
        </StyledModal.Body>
        <StyledModal.Footer>
          <StyledButton
            width="100%"
            height="60px"
            form="user-consent-modal-form"
            type="submit"
            data-testid="user-consent-form-button"
            disabled={!isValid || isSuccessRegisterUserConsent}
            isLoading={isLoadingRegisterUserConsent}
          >
            <I18NText id="confirm" preset="cta-lg" />
          </StyledButton>
        </StyledModal.Footer>
      </StyledModal>
      <SuccessToast
        show={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
        translationKey="user.consent.modal.success"
      />
      <ErrorToast
        show={showErrorToast}
        onClose={() => setShowErrorToast(false)}
        translationKey={
          errorRegisterUserConsent?.response?.data
            .errorKey as unknown as TTranslationKey
        }
      />
    </>
  )
}
