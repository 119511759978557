import { IPositionDetails } from '@unfoldrtech/portal-mic'
import { TPlacementSelectionData } from '../../models/types/TPlacementSelectionData'

export const transformPositionsToPlacementOptions = (
  positions: IPositionDetails[],
  filterCondition?: (position: IPositionDetails) => boolean
): TPlacementSelectionData => {
  const result: TPlacementSelectionData = {}

  const defaultFilter = (position: IPositionDetails) => !!position

  const filterMethod = filterCondition || defaultFilter

  positions.filter(filterMethod).forEach((position) => {
    const currentPlatformValue = result[position.platformType] ?? {}
    const currentPageValue = currentPlatformValue[position.page] ?? []

    result[position.platformType] = {
      ...currentPlatformValue,
      [position.page]: [...currentPageValue, position],
    }
  })

  return result
}
