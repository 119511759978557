import React from 'react'
import { EPacing } from '@unfoldrtech/portal-mic'
import I18NText from '../../I18NText'

export function CurrentPacingText({ pacing }: { pacing?: EPacing }) {
  if (pacing === EPacing.Even) {
    return (
      <>
        <I18NText id="advertiser.campaigns.new.settings.pacing.even" />
      </>
    )
  }
  if (pacing === EPacing.Asap) {
    return (
      <>
        <I18NText id="advertiser.campaigns.new.settings.pacing.asap" />
      </>
    )
  }
  return <> </>
}
