import React, { ReactElement, ReactNode, useState } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { StyledFormInput2 } from './StyledFormInput2'
import { Container } from '../containers'
import { IInputProps } from '../../../models/interfaces'
import {
  TAlignmentMixin,
  TBorderMixin,
  TFontStyleMixin,
  TSizeMixin,
} from '../styles/mixins'
import { ErrorText } from '../fonts/ErrorText'

export interface IStyledInput2Props
  extends Omit<IInputProps, 'prefix' | 'suffix'> {
  fontStyle?: TFontStyleMixin
  inputSize?: TSizeMixin
  inputBorder?: TBorderMixin
  inputAlignment?: TAlignmentMixin
  inputPadding?: Pick<CSSProperties, 'padding' | 'paddingLeft' | 'paddingRight'>
  containerSize?: TSizeMixin
  containerBorder?: TBorderMixin
  containerAlignment?: TAlignmentMixin
  prefix?: ReactNode | ReactNode[]
  suffix?: ReactNode | ReactNode[]
  label?: string
  labelTransform?: string
  showErrorText?: boolean
}

export const InputContainer = styled(Container)<{
  hasPrefix?: boolean
  transform?: string
}>`
  label {
    position: absolute;
    transform-origin: top left;
    top: 0;
    left: 0;
    color: var(--font-color-placeholder);
    ${(p) =>
      p.hasPrefix
        ? `transform: translate(30px, 19px) scale(1);`
        : `transform: translate(23px, 20px) scale(1);`}
    ${(p) => (p.transform ? `transform: ${p.transform}` : '')}
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &.float {
      transform: translate(14px, -9px) scale(0.75);
    }
  }
  input::placeholder {
    transition: 0.3s linear;
  }

  input {
    border: none;
  }

  fieldset {
    text-align: left;
    position: absolute;
    inset: -5px 0px 0px;
    margin: 0px;
    padding: 0px 8px;
    pointer-events: none;
    border-radius: inherit;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: var(--border-color-default);

    legend {
      max-width: 0.01px;
      float: unset;
      font-size: 12px;

      width: auto;
      overflow: hidden;
      display: block;
      padding: 0px;
      height: 11px;

      visibility: hidden;

      transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      white-space: nowrap;

      span {
        padding-left: 5px;

        display: inline-block;
        opacity: 0;
        visibility: visible;
      }
    }
    &.float {
      legend {
        width: auto;
        max-width: 100%;
        span {
          opacity: 0;
        }
      }
    }
  }
`

// todo: Rename to StyledInput and replace current input
export const StyledInput2 = React.forwardRef(
  (
    {
      onChange,
      field,
      error,
      prefix,
      id,
      suffix,
      fontStyle,
      inputSize,
      inputBorder,
      inputAlignment,
      inputPadding,
      containerSize,
      containerBorder,
      containerAlignment,
      value,
      placeholder,
      label,
      hidden,
      labelTransform,
      showErrorText = true,
      ...props
    }: IStyledInput2Props,
    ref
  ): ReactElement => {
    const intialActiveState =
      !!value || (props.type === 'number' && value && Number(value) >= 0)
    const [isActive, setIsActive] = useState(intialActiveState)
    const inputRef = ref || field?.ref || null

    return (
      <Container display={hidden ? 'none' : 'block'}>
        <InputContainer
          hasPrefix={!!prefix}
          display="flex"
          border={{
            radius: '2px',
          }}
          height="64px"
          className={error ? 'input-error' : ''}
          backgroundColor={props.disabled ? 'var(--border-color-default)' : ''}
          opacity={props.disabled ? '0.5' : ''}
          {...containerBorder}
          {...containerSize}
          {...containerAlignment}
          position="relative"
          cursor="pointer"
          transform={labelTransform}
        >
          {prefix}
          {label && (
            <label
              style={{ cursor: 'text' }}
              htmlFor={id}
              className={isActive ? 'float' : ''}
            >
              {label}
            </label>
          )}
          <StyledFormInput2
            id={id}
            {...field}
            {...fontStyle}
            {...inputSize}
            {...inputBorder}
            {...inputAlignment}
            {...inputPadding}
            {...props}
            hidden={hidden}
            placeholder={isActive && placeholder ? placeholder : undefined}
            ref={inputRef}
            onSelect={() => setIsActive(true)}
            onBlur={() =>
              (props.type !== 'number'
                ? !value
                : value === undefined || value === '') && setIsActive(false)
            }
            onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
              field?.onChange(v)
              if (onChange) {
                onChange(v)
              }
            }}
          />
          {suffix}
          <fieldset
            className={isActive && label ? 'float' : ''}
            aria-hidden="true"
          >
            <legend>
              <span>{label}</span>
            </legend>
          </fieldset>
        </InputContainer>
        {error && showErrorText && (
          <ErrorText data-testid={`${id}-error`}>{error.message}</ErrorText>
        )}
      </Container>
    )
  }
)
