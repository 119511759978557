import React from 'react'
import { ECreativeStatus } from '@unfoldrtech/portal-mic'
import { ActiveBadge } from './ActiveBadge'
import { InactiveBadge } from './InactiveBadge'
import I18NText from '../../I18NText'
import { DisabledBadge } from './DisabledBadge'
import { PendingBadge } from './PendingBadge'

export function CreativeStatusBadge({
  creativeStatus,
}: {
  creativeStatus: ECreativeStatus
}) {
  if (creativeStatus === ECreativeStatus.Active) {
    return (
      <ActiveBadge pill>
        <I18NText id="approved" />
      </ActiveBadge>
    )
  }
  if (creativeStatus === ECreativeStatus.Inactive) {
    return (
      <InactiveBadge pill>
        <I18NText id="inactive" />
      </InactiveBadge>
    )
  }
  if (creativeStatus === ECreativeStatus.Disabled) {
    return (
      <DisabledBadge pill>
        <I18NText id="disabled" />
      </DisabledBadge>
    )
  }
  if (creativeStatus === ECreativeStatus.Rejected) {
    return (
      <InactiveBadge pill>
        <I18NText id="rejected" />
      </InactiveBadge>
    )
  }
  if (creativeStatus === ECreativeStatus.Pending) {
    return (
      <PendingBadge pill>
        <I18NText id="pending" />
      </PendingBadge>
    )
  }
  return <></>
}
