import { EUserRole } from '@unfoldrtech/portal-mic'
import { IAppBarProps } from '../../models/interfaces'
import { TReportSidebarItem } from '../../models/types'

export const NAVBAR_INVENTORY_PROPS: IAppBarProps = {
  title: 'retailer.nav.inventory',
  navLinkItems: [
    {
      title: 'retailer.inventory.nav.sponsoredProducts',
      path: 'sponsored-products',
    },
    {
      title: 'retailer.inventory.nav.displayAds',
      path: 'display-ads',
    },
    {
      title: 'retailer.inventory.nav.digital.out.of.home',
      path: 'dooh',
    },
  ],
}

export const NAV_ITEMS_RETAILER_ADVERTISER: IAppBarProps = {
  navLinkItems: [
    {
      title: 'retailer.advertisers.nav.overview',
      path: 'overview', // dynamically set the route based on selected org
    },
    {
      title: 'retailer.advertisers.nav.channels',
      path: 'channels',
    },
    {
      title: 'retailer.advertisers.nav.products',
      path: 'products',
    },
    {
      title: 'retailer.advertisers.nav.users',
      path: 'users',
    },
  ],
}

export const NAVBAR_DIRECT_DEALS_PROPS: IAppBarProps = {
  title: 'direct.deals',
  navLinkItems: [
    {
      title: 'all.channels',
      path: 'all-channels',
    },
    {
      title: 'display.ads',
      path: 'display-ads',
    },
    {
      title: 'digital.out.of.home',
      path: 'digital-out-of-home',
    },
  ],
}

export const RETAILER_OVERVIEW_SIDEBAR_ELEMENTS: Array<TReportSidebarItem> = [
  {
    label: 'retailer.nav.advertisers',
    type: 'header',
  },
  {
    label: 'all',
    type: 'item',
    url: 'advertisers-all',
  },
  {
    label: 'advertiser.nav.campaigns',
    type: 'header',
  },
  {
    label: 'all',
    type: 'item',
    url: 'campaigns-all',
  },
  {
    label: 'retailer.inventory.nav.sponsoredProducts',
    type: 'item',
    url: 'campaigns-sponsoredProducts',
  },
  {
    label: 'retailer.inventory.nav.displayAds',
    type: 'item',
    url: 'campaigns-da',
  },
  {
    label: 'retailer.inventory.nav.digital.out.of.home',
    type: 'item',
    url: 'campaigns-dooh',
  },
  // {
  //   label: 'retailer.inventory.nav.displayAds',
  //   type: 'item',
  //   url: 'campaigns-displayAds',
  //   isDisabled: true,
  //   tooltipKey: 'coming.soon',
  // },
  /* {
    label: 'advertiser.campaigns.new.review.ad.groups',
    type: 'header',
  },
  {
    label: 'all',
    type: 'item',
    url: 'adgroups-all',
  },
  {
    label: 'retailer.nav.inventory.categories',
    type: 'item',
    url: 'adgroups-categories',
  },
  {
    label: 'retailer.nav.inventory.search',
    type: 'item',
    url: 'adgroups-search',
  }, */
  {
    label: 'retailer.nav.products',
    type: 'header',
  },
  {
    label: 'all',
    type: 'item',
    url: 'products',
  },
]

/**
 * Each element should be processed by the page
 * Disable settings page if campaign has started
 */
export const RETAILER_ADVERTISER_SIDEBAR_ELEMENTS: Array<TReportSidebarItem> = [
  {
    label: 'advertiser.nav.campaigns',
    type: 'header',
  },
  {
    label: 'all',
    type: 'item',
    url: 'campaigns-all',
  },
  {
    label: 'retailer.inventory.nav.sponsoredProducts',
    type: 'item',
    url: 'campaigns-sponsoredProducts',
  },
  {
    label: 'retailer.inventory.nav.displayAds',
    type: 'item',
    url: 'campaigns-da',
  },
  {
    label: 'retailer.inventory.nav.digital.out.of.home',
    type: 'item',
    url: 'campaigns-dooh',
  },
  // {
  //   label: 'retailer.inventory.nav.displayAds',
  //   type: 'item',
  //   url: 'campaigns-displayAds',
  //   isDisabled: true,
  //   tooltipKey: 'coming.soon',
  // },
]

/**
 * Each element should be processed by the page
 * Disable settings page if campaign has started
 */
export const RETAILER_CAMPAIGN_SIDEBAR_ELEMENTS: Array<TReportSidebarItem> = [
  {
    label: 'advertiser.nav.campaign',
    type: 'header',
  },
  {
    label: 'advertiser.campaigns.new.review.ad.groups',
    type: 'item',
    url: 'adgroups',
  },
  {
    label: 'retailer.advertisers.nav.creatives',
    type: 'item',
    url: 'creatives',
  },
  {
    label: 'retailer.advertisers.nav.positions',
    type: 'item',
    url: 'positions',
  },
  {
    label: 'retailer.advertisers.nav.audiences',
    type: 'item',
    url: 'audiences',
  },
]

/**
 * Each element should be processed by the page
 * Disable settings page if campaign has started
 */
export const RETAILER_ADGROUPS_SIDEBAR_ELEMENTS: Array<TReportSidebarItem> = [
  {
    label: 'advertiser.nav.adgroup',
    type: 'header',
  },
  {
    label: 'retailer.advertisers.nav.creatives',
    type: 'item',
    url: 'creatives',
  },
  {
    label: 'retailer.advertisers.nav.positions',
    type: 'item',
    url: 'positions',
  },
  {
    label: 'retailer.advertisers.nav.products',
    type: 'item',
    url: 'products',
  },
  {
    label: 'retailer.nav.inventory.categories',
    type: 'item',
    url: 'categories',
  },
  {
    label: 'retailer.nav.inventory.keywords',
    type: 'item',
    url: 'keywords',
  },
  {
    label: 'retailer.nav.inventory.search.terms',
    type: 'item',
    url: 'search-terms',
  },
  // disabled until implememntation of pagePosition reporting PORT-713
  // {
  //   label: 'retailer.nav.inventory.pagePosition',
  //   type: 'item',
  //   url: 'pagePosition',
  // },
  {
    label: 'retailer.advertisers.nav.locations',
    type: 'item',
    url: 'locations',
  },
  {
    label: 'retailer.advertisers.nav.audiences',
    type: 'item',
    url: 'audiences',
  },
]

export const RETAILER_CREATIVES_SIDEBAR_ELEMENTS: IAppBarProps = {
  navLinkItems: [
    {
      title: 'retailer.nav.fallbacks',
      path: 'fallbacks',
    },
    {
      title: 'retailer.nav.templates',
      path: 'templates',
      authRole: EUserRole.RetailerInsights,
    },
  ],
}

export const RETAILER_REVIEW_DA_CREATIVES_APPBAR_ELEMENTS: IAppBarProps = {
  navLinkItems: [
    {
      title: 'retailer.nav.display.ads',
      path: 'display-ads',
    },
  ],
}

export const RETAILER_REVIEW_DOOH_CREATIVES_APPBAR_ELEMENTS: IAppBarProps = {
  navLinkItems: [
    {
      title: 'retailer.nav.dooh',
      path: 'dooh',
    },
  ],
}

export const RETAILER_REVIEW_ALL_CREATIVES_APPBAR_ELEMENTS: IAppBarProps = {
  navLinkItems: [
    ...RETAILER_REVIEW_DA_CREATIVES_APPBAR_ELEMENTS.navLinkItems!,
    ...RETAILER_REVIEW_DOOH_CREATIVES_APPBAR_ELEMENTS.navLinkItems!,
  ],
}
