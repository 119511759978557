import React, { ReactNode } from 'react'
import Carousel, { CarouselProps, CarouselState } from 'nuka-carousel'
import { IconButton } from '../../buttons'
import { IconArrowRight, IconArrowBack } from '../../icons'

export function StyledCarousel({
  slidesToScroll = 1,
  slidesToShow = 3,
  props,
  state,
  children,
}: {
  slidesToScroll?: number
  slidesToShow?: number
  props?: CarouselProps
  state?: CarouselState
  children?: ReactNode | ReactNode[]
}) {
  return (
    <Carousel
      slidesToScroll={slidesToScroll}
      slidesToShow={slidesToShow}
      cellSpacing={5}
      renderCenterLeftControls={({ previousSlide, currentSlide }) => {
        if (currentSlide !== 0) {
          return (
            <IconButton onClick={previousSlide}>
              <IconArrowBack />
            </IconButton>
          )
        }
      }}
      renderCenterRightControls={({ nextSlide, slideCount, currentSlide }) => {
        if (currentSlide < slideCount - slidesToShow) {
          return (
            <IconButton onClick={nextSlide}>
              <IconArrowRight />
            </IconButton>
          )
        }
      }}
      renderBottomCenterControls={null}
      {...props}
      {...state}
    >
      {children}
    </Carousel>
  )
}
