export const PORTAL_FF_DA = 'portal-ff-da'
export const PORTAL_FF_DA_AUDIENCES_TARGETING_VIEW =
  'portal-ff-da-audiences-targeting-view'
export const PORTAL_FF_DA_AUDIENCES_TARGETING_EDIT =
  'portal-ff-da-audiences-targeting-edit'
export const PORTAL_FF_DA_AUDIENCES_ANALYTICS_VIEW =
  'portal-ff-da-audiences-analytics-view'
export const PORTAL_FF_DA_DAY_TIME_TARGETING_VIEW =
  'portal-ff-da-day-time-targeting-view'
export const PORTAL_FF_DA_DAY_TIME_TARGETING_EDIT =
  'portal-ff-da-day-time-targeting-edit'
