import { PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import {
  IDOOHCampaign,
  IDOOHAdGroup,
  IDOOHAdGroupTargeting,
  IDOOHAdGroupScreenPositionTarget,
} from '../../../models'
import { getSelectedDoohAdGroup } from '../utils'

const setDoohCampaignAdGroup = (
  state: WritableDraft<IDOOHCampaign>,
  action: PayloadAction<
    IDOOHAdGroup & {
      id: number
      locationType?: string
      targeting: IDOOHAdGroupTargeting & {
        screenPosition: IDOOHAdGroupScreenPositionTarget
      }
    }
  >
) => {
  const selectedAdGroup = getSelectedDoohAdGroup(state, action.payload.id)

  if (selectedAdGroup) {
    Object.assign(selectedAdGroup, action.payload)
  } else {
    state.adGroupsList.push(action.payload)
  }
}

export default setDoohCampaignAdGroup
