import { EOrgType } from '@unfoldrtech/portal-mic'
import useGetAdvertiserReportingSPProductsTableV3 from '../../../../hooks/use-get-advertiser-reporting-sp-products-table-v3'
import useGetAdvertiserReportingSPProductsChartV3 from '../../../../hooks/use-get-advertiser-reporting-sp-products-chart-v3'
import useGetRetailerReportingSPProductsTableV3 from '../../../../hooks/use-get-retailer-reporting-sp-products-table-v3'
import useGetRetailerReportingSPProductsChartV3 from '../../../../hooks/use-get-retailer-reporting-sp-products-chart-v3'

export const getTableAndChartSPProductsFn = ({
  orgType = EOrgType.Advertiser,
}: {
  orgType?: EOrgType
}) => {
  let tableFn
  let chartFn
  if (orgType === EOrgType.Advertiser) {
    tableFn = useGetAdvertiserReportingSPProductsTableV3
    chartFn = useGetAdvertiserReportingSPProductsChartV3
  }
  if (orgType === EOrgType.Retailer) {
    tableFn = useGetRetailerReportingSPProductsTableV3
    chartFn = useGetRetailerReportingSPProductsChartV3
  }

  return { tableFn, chartFn }
}
