import React from 'react'
import {
  ECampaignStatus,
  ECreativeStatus,
  EStatus,
} from '@unfoldrtech/portal-mic'
import { ActiveBadge } from './ActiveBadge'
import { InactiveBadge } from './InactiveBadge'
import I18NText from '../../I18NText'
import { DisabledBadge } from './DisabledBadge'
import { RejectedBadge } from './RejectedBadge'
import { CompletedBadge } from './CompletedBadge'
import { PendingBadge } from './PendingBadge'
import { AcceptedBadge } from './AcceptedBadge'

export function CurrentStatusBadge({
  status,
}: {
  status: EStatus | ECampaignStatus | ECreativeStatus
}) {
  if (status === ECampaignStatus.Active) {
    return (
      <ActiveBadge pill>
        <I18NText id="active" />
      </ActiveBadge>
    )
  }
  if (status === ECampaignStatus.Inactive) {
    return (
      <InactiveBadge pill>
        <I18NText id="inactive" />
      </InactiveBadge>
    )
  }
  if (status === ECampaignStatus.Disabled) {
    return (
      <DisabledBadge pill>
        <I18NText id="disabled" />
      </DisabledBadge>
    )
  }
  if (status === ECampaignStatus.Accepted) {
    return (
      <AcceptedBadge pill>
        <I18NText id="accepted" />
      </AcceptedBadge>
    )
  }
  if (status === ECampaignStatus.Pending) {
    return (
      <PendingBadge pill>
        <I18NText id="pending" />
      </PendingBadge>
    )
  }
  if (status === ECampaignStatus.Completed) {
    return (
      <CompletedBadge pill>
        <I18NText id="completed" />
      </CompletedBadge>
    )
  }
  if (status === ECampaignStatus.Rejected) {
    return (
      <RejectedBadge pill>
        <I18NText id="rejected" />
      </RejectedBadge>
    )
  }

  return <>{status}</>
}
